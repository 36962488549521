import { parse as parseEvent, getPath } from '../util/events';
import promisify from '../util/promise';
import waitForDOMReady from '../util/dom-ready';
import elementIsInDOM from '../util/element-is-in-dom';

const BASE_CONTROLLER_HANDLERS = Symbol( 'BASE_CONTROLLER_HANDLERS' );

interface Listener {
		target: EventTarget
		selector: string|null
		event: string
		handler: <T extends Event>( e: T|Event ) => void
		options: EventListenerOptions|boolean|undefined
}

export class BaseController<T extends HTMLElement> {
	el: HTMLElement|T;

	[BASE_CONTROLLER_HANDLERS]: Array<Listener> = [];

	constructor( el: HTMLElement|T ) {
		this.el = el;

		const elementDisappearedMessage = 'The element has disappeared';

		this.resolve().then( () => {
			if ( !elementIsInDOM( this.el ) ) {
				return Promise.reject( new Error( elementDisappearedMessage ) );
			}

			this.el.classList.add( 'is-resolved' );

			const init = () => {
				return promisify( () => {
					if ( !elementIsInDOM( this.el ) ) {
						return Promise.reject( new Error( elementDisappearedMessage ) );
					}

					return this.init();
				} );
			};

			const render = () => {
				return promisify( () => {
					if ( !elementIsInDOM( this.el ) ) {
						return Promise.reject( new Error( elementDisappearedMessage ) );
					}

					return this.render();
				} );
			};

			const bind = () => {
				return promisify( () => {
					if ( !elementIsInDOM( this.el ) ) {
						return Promise.reject( new Error( elementDisappearedMessage ) );
					}

					return this.bind();
				} );
			};

			return init().then( () => {
				return render();
			} ).then( () => {
				return bind();
			} ).then( () => {
				return this;
			} );
		} ).catch( ( err ) => {
			// Because the lifecycle of CEH is promisified it happens a lot that elements are starting to come alive
			// while at the same time the DOM is changed.
			// JS will keep trying to start the controller while the DOM element is already removed.
			// This is normal and handled gracefully in native elements.
			// If we detect this scenario we try to clean up and shut down silently.
			if ( err && ( err.message === elementDisappearedMessage ) ) {
				console.warn( this.el.tagName, err );

				try {
					this.unbind();
					this.destroy();
				} catch {
					// noop;
				}
			} else {
				// These are errors from the lifecycle of the sub class.
				// Do not silence these!
				throw err;
			}
		} );
	}

	destroy(): void {
		this.el.classList.remove( 'is-resolved' );
	}

	resolve(): Promise<void> {
		return waitForDOMReady();
	}

	init(): Promise<void>|void {
		return;
	}

	render(): Promise<void>|void {
		return;
	}

	bind(): Promise<void>|void {
		return;
	}

	unbind(): void {
		if ( this[BASE_CONTROLLER_HANDLERS] ) {
			this[BASE_CONTROLLER_HANDLERS].forEach( ( listener ) => {
				listener.target.removeEventListener( listener.event, listener.handler, listener.options );
			} );

			this[BASE_CONTROLLER_HANDLERS] = [];
		}
	}

	on<U extends Event>( name: string, handler: ( e: U|Event, target: EventTarget|null ) => void, target: Element|Window|null = null, options: EventListenerOptions|boolean|undefined = false ): void {
		this[BASE_CONTROLLER_HANDLERS] = this[BASE_CONTROLLER_HANDLERS] || [];

		const {
			event, selector,
		} = parseEvent( name );

		const parsedTarget = target || this.el;

		let wrappedHandler = function( e: U|Event ) {
			handler( e, e.target );
		};

		if ( selector ) {
			wrappedHandler = function( e ) {
				const path = getPath( e );

				const matchingTarget = path.find( ( tag ) => {
					if ( tag instanceof Element ) {
						return tag.matches( selector );
					}

					return false;
				} );

				if ( matchingTarget ) {
					handler( e, matchingTarget );
				}
			};
		}

		const listener = {
			target: parsedTarget,
			selector: selector,
			event: event,
			handler: wrappedHandler,
			options: options,
		};

		listener.target.addEventListener( listener.event, listener.handler, listener.options );

		this[BASE_CONTROLLER_HANDLERS].push( listener );
	}

	once<U extends Event>( name: string, handler: ( e: U|Event, target: EventTarget|null ) => void, target: Element|Window|null = null, options: EventListenerOptions|boolean|undefined = false ): void {
		const wrappedHandler = ( e: U|Event, matchingTarget: EventTarget|null ) => {
			this.off( name, target );
			handler( e, matchingTarget );
		};

		this.on( name, wrappedHandler, target, options );
	}

	off( name: string, target: EventTarget|null = null ): void {
		this[BASE_CONTROLLER_HANDLERS] = this[BASE_CONTROLLER_HANDLERS] || [];

		const {
			event, selector,
		} = parseEvent( name );
		const parsedTarget = target || this.el;

		const listener = this[BASE_CONTROLLER_HANDLERS].find( ( handler ) => {
			// Selectors don't match
			if ( handler.selector !== selector ) {
				return false;
			}

			// Event type don't match
			if ( handler.event !== event ) {
				return false;
			}

			// Parsed a target, but the targets don't match
			if ( !!parsedTarget && handler.target !== parsedTarget ) {
				return false;
			}

			// Else, we found a match
			return true;
		} );

		if ( !!listener && !!listener.target ) {
			this[BASE_CONTROLLER_HANDLERS].splice( this[BASE_CONTROLLER_HANDLERS].indexOf( listener ), 1 );

			listener.target.removeEventListener( listener.event, listener.handler, listener.options );
		}
	}

	emit( name: string, data = {}, options = {} ): void {
		const params = Object.assign( {
			detail: data,
			bubbles: true,
			cancelable: true,
		}, options );

		const event = new CustomEvent( name, params );

		this.el.dispatchEvent( event );
	}
}
