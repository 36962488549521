/* eslint-disable @typescript-eslint/no-explicit-any */

interface Stringable {
	toString(): string
}

interface StringGetterSetterPair {
	getter(): string | Stringable | null
	setter( x: string|Stringable|null ): void
}

export function generateStringAttributeMethods( attribute: string ): StringGetterSetterPair {
	const getter = function( this: { el: HTMLElement } ): string|Stringable|null {
		return this.el.getAttribute( attribute );
	};

	const setter = function( this: { el: HTMLElement }, x: string | Stringable | null ): void {
		let parsed = null;
		if ( x && x.toString ) {
			parsed = x.toString();
		}

		const oldValue = getter.call( this );

		if ( parsed === oldValue ) {
			return;
		}

		if ( parsed ) {
			this.el.setAttribute( attribute, parsed );
		} else {
			this.el.removeAttribute( attribute );
		}
	};

	return {
		getter,
		setter,
	};
}

interface BooleanGetterSetterPair {
	getter(): boolean
	setter( x: boolean ): void
}

export function generateBoolAttributeMethods( attribute: string ): BooleanGetterSetterPair {
	const getter = function( this: { el: HTMLElement } ): boolean {
		return this.el.hasAttribute( attribute );
	};

	const setter = function( this: { el: HTMLElement }, x: boolean ): void {
		const parsed = ( 'string' === typeof x ) || !!x;
		const oldValue = getter.call( this );

		if ( parsed === oldValue ) {
			return;
		}

		if ( parsed ) {
			this.el.setAttribute( attribute, '' );
		} else {
			this.el.removeAttribute( attribute );
		}
	};

	return {
		getter,
		setter,
	};
}

interface IntegerGetterSetterPair {
	getter(): number|null
	setter( x: number|null ): void
}

export function generateIntegerAttributeMethods( attribute: string ): IntegerGetterSetterPair {
	const getter = function( this: { el: HTMLElement } ): number | null {
		const x = this.el.getAttribute( attribute );
		if ( !x ) {
			return null;
		}

		return parseInt( x, 10 );
	};

	const setter = function( this: { el: HTMLElement }, x: number | null ): void {
		let parsed = null;
		if ( 'string' === typeof x ) {
			parsed = parseInt( x, 10 ); // keep string support internally
		} else {
			parsed = x;
		}

		const oldValue = getter.call( this );

		if ( parsed === oldValue ) {
			return;
		}

		if ( null !== parsed && 'undefined' !== typeof parsed && !Number.isNaN( parsed ) ) {
			this.el.setAttribute( attribute, parsed.toString() );
		} else {
			console.warn( `Could not set ${attribute} to ${x}` );
			this.el.removeAttribute( attribute );
		}
	};

	return {
		getter,
		setter,
	};
}

interface NumberGetterSetterPair {
	getter(): number|null
	setter( x: number|null ): void
}

export function generateNumberAttributeMethods( attribute: string ): NumberGetterSetterPair {
	const getter = function( this: { el: HTMLElement } ): number | null {
		const x = this.el.getAttribute( attribute );
		if ( !x ) {
			return null;
		}

		return parseFloat( x );
	};

	const setter = function( this: { el: HTMLElement }, x: number | null ): void {
		let parsed = null;
		if ( 'string' === typeof x ) {
			parsed = parseFloat( x ); // keep string support internally
		} else {
			parsed = x;
		}

		const oldValue = getter.call( this );

		if ( parsed === oldValue ) {
			return;
		}

		if ( parsed && !Number.isNaN( parsed ) ) {
			this.el.setAttribute( attribute, parsed.toString() );
		} else {
			console.warn( `Could not set ${attribute} to ${x}` );
			this.el.removeAttribute( attribute );
		}
	};

	return {
		getter,
		setter,
	};
}

interface JSONGetterSetterPair<T> {
	getter(): T | string | null
	setter( x: T | string | null ): void
}

export function generateJSONAttributeMethods<T>( attribute: string ): JSONGetterSetterPair<T> {
	// @param value  Whatever you want to parse
	// @param strict If true, return null when non-JSON parsed
	//               If false, return whatever was passed to parse
	const parse = function( value: T|string|null, strict = false ): T|string|null {
		if ( 'string' === typeof value ) {
			try {
				const decoded = JSON.parse( value );

				if ( decoded ) {
					return decoded as T;
				}
			} catch {
				// noop
			}
		}

		if ( strict ) {
			return null;
		}

		return value;
	};

	const getter = function( this: { el: HTMLElement } ): T | string | null {
		const value = this.el.getAttribute( attribute );

		return parse( value, true );
	};

	const setter = function( this: { el: HTMLElement }, x: T | string | null ): void {
		if ( !x ) {
			this.el.removeAttribute( attribute );

			return;
		}

		const encoded = JSON.stringify( parse( x ) );
		const oldValue = this.el.getAttribute( attribute );

		if ( encoded === oldValue ) {
			return;
		}

		if ( encoded ) {
			this.el.setAttribute( attribute, encoded );
		} else {
			this.el.removeAttribute( attribute );
		}
	};

	return {
		getter,
		setter,
	};
}

interface GetterSetterPair {

	getter(): any

	setter( x: any ): void
}

export function generateAttributeMethods( attribute: string, type = 'string' ): GetterSetterPair {
	if ( 'bool' === type || 'boolean' === type ) {
		return generateBoolAttributeMethods( attribute );
	} else if ( 'int' === type || 'integer' === type ) {
		return generateIntegerAttributeMethods( attribute );
	} else if ( 'float' === type || 'number' === type ) {
		return generateNumberAttributeMethods( attribute );
	} else if ( 'string' === type ) {
		return generateStringAttributeMethods( attribute );
	} else if ( 'json' === type ) {
		return generateJSONAttributeMethods( attribute );
	}

	return {
		getter: function(): null {
			return null;
		},
		setter: function(): void {
			return;
		},
	};
}
